import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PropTypes from "prop-types";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <SEO
        keywords={[
          `bytewrap`,
          `web design`,
          `web development`,
          `mobile apps`,
          `blockchain`,
          `eos dapps`,
        ]}
        title='Your web & mobile apps development company'
      />

      <article
        className='blog-post'
        itemScope
        itemType='http://schema.org/Article'>
        <header>
          <h1 className='mb-4' itemProp='headline'>
            {post.frontmatter.title}
          </h1>
          <p className='text-lg text-center mb-12 mt-8'>
            {post.frontmatter.category.join("  •  ")}
          </p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp='articleBody'
        />
      </article>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
